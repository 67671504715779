import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AhCommonDialogService } from '@common/angular/utils';
import { Observable, Observer } from 'rxjs';

export interface PreventUnsavedChanges {
  canGoBack(): boolean;
  onStay?(): void;
}

@Injectable({
  providedIn: 'root'
})
export class PreventUnsavedChangesGuard

{
  constructor(
    private dialogService: AhCommonDialogService,
    private router: Router
  ) {}

  canDeactivate(component: PreventUnsavedChanges): Observable<boolean> | boolean {
    const currentNavigation = this.router.getCurrentNavigation();
    const navState = currentNavigation?.extras?.state;
    if (component.canGoBack() || navState?.['ignoreGuardCheck']) return true;
    return new Observable((observer: Observer<boolean>) => {
      this.dialogService.openDefaultModal({
        header: 'Confirmation',
        message: 'You have unsaved changes! Are you sure you want to leave this page?',
        icon: 'warning',
        accept: () => { 
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          component.onStay?.();
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
}
