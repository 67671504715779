import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-search-input',
  templateUrl: './search-input-type.component.html',
  styleUrls: ['./search-input-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputTypeComponent extends FieldType<FieldTypeConfig> {

  override defaultOptions = {
    templateOptions: {
      width: '100%',
      type: 'text'
    }
  };

  cancel(): void {
    this.field.props['cancel']();
  }
}
