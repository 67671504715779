import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { CountriesActions } from './countries.actions';
import * as reducer from './countries.reducer';
import { CountriesSelectors } from './countries.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class CountriesFacade {
  loaded$ = this.store.select(CountriesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(
    this.store.select(CountriesSelectors.selectTranslatedItems),
    this.loaded$
  );

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(CountriesActions.get());
  }

  getCountryById(countryId: string): Observable<string> {
    return this.store.select(CountriesSelectors.selectCountryById(countryId));
  }
}
