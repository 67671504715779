import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { scopeLoader } from '@common/angular/translation';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';

import { FEATURE_NAME, reducer } from './+state/reports.reducer';
import { ReportGenerationEffects } from './+state/report-generation/report-generation.effects';
import { ReportGenerationFacade } from './+state/report-generation/report-generation.facade';

import {
  DomainReportsGenerationService,
  DomainReportsMessageService,
  DomainReportsStateService
} from './services';

import { ReportsPreviewDialogComponent } from './components';
import { NgLetModule } from 'ng-let';
import { ButtonDirective } from 'primeng/button';
import { Ripple } from 'primeng/ripple';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([ReportGenerationEffects]),
    SersiAngularUiSpinModule,
    PdfViewerModule,
    NgLetModule,
    ButtonDirective,
    Ripple
  ],
  exports: [ReportsPreviewDialogComponent],
  declarations: [ReportsPreviewDialogComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'domain-reports',
        alias: 'domain-reports',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    },
    DomainReportsStateService,
    DomainReportsMessageService,
    DomainReportsGenerationService,
    ReportGenerationFacade
  ]
})
export class StockFlowDomainReportsModule {}
