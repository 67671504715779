import { createReducer, on } from '@ngrx/store';
import { ReportGenerationActions } from './report-generation.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(
    ReportGenerationActions.generateSummary,
    ReportGenerationActions.generateBuyOrder,
    (state, action) => ({
      ...state,
      loading: !action.isPreview,
      loaded: false
    })
  ),

  on(
    ReportGenerationActions.generateSummarySuccess,
    ReportGenerationActions.generateBuyOrderSuccess,
    (state) => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(ReportGenerationActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(ReportGenerationActions.reset, () => ({
    ...initialState
  }))
);

export const getLoaded = (state: State): boolean => state.loaded;

export const getLoading = (state: State): boolean => state.loading;
