<quill-editor
  class="w-full"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props['placeholder']"
  [modules]="props['modules']"
>
  <div quill-editor-toolbar *ngIf="props['modules']['toolbar']">
    <span class="ql-formats">
      <select class="ql-header">
        <option value="1">
          {{ props['toolbarLabels']['formats']['headingTitle'] }}
        </option>
        <option value="2">
          {{ props['toolbarLabels']['formats']['subHeadingTitle'] }}
        </option>
        <option selected>
          {{ props['toolbarLabels']['formats']['normalTitle'] }}
        </option>
      </select>
      <select class="ql-font">
        <option selected>Sans Serif</option>
        <option value="serif">Serif</option>
        <option value="monospace">Monospace</option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-bold" aria-label="Bold" type="button"></button>
      <button class="ql-italic" aria-label="Italic" type="button"></button>
      <button
        class="ql-underline"
        aria-label="Underline"
        type="button"
      ></button>
    </span>
    <span class="ql-formats">
      <select class="ql-color"></select>
      <select class="ql-background"></select>
    </span>
    <span class="ql-formats">
      <button
        class="ql-list"
        value="ordered"
        aria-label="Ordered List"
        type="button"
      ></button>
      <button
        class="ql-list"
        value="bullet"
        aria-label="Unordered List"
        type="button"
      ></button>
      <select class="ql-align">
        <option selected></option>
        <option value="center">center</option>
        <option value="right">right</option>
        <option value="justify">justify</option>
      </select>
    </span>
  </div>
</quill-editor>
