import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Report Generation]';

const generateSummary = createAction(
  `${NAMESPACE} Generate Procurement Summary`,
  props<{ procurementId: string; isPreview: boolean }>()
);
const generateSummarySuccess = createAction(
  `${NAMESPACE} Generate Procurement Summary Success`
);

const generateBuyOrder = createAction(
  `${NAMESPACE} Generate Buy Order`,
  props<{ procurementId: string; isPreview: boolean }>()
);
const generateBuyOrderSuccess = createAction(
  `${NAMESPACE} Generate Buy Order Success`
);

const reset = createAction(`${NAMESPACE} Reset`);
const error = createAction(`${NAMESPACE} Error`);

export const ReportGenerationActions = {
  generateSummary,
  generateSummarySuccess,
  generateBuyOrder,
  generateBuyOrderSuccess,
  reset,
  error
};
