import { SelectListItemWithTranslationDto } from '@common/angular/core/models';
import { CommonUserProfileSelectors } from '@common/angular/features/user-profile';
import { convertSelectListItemDtoToSelectListItem } from '@common/angular/translation';
import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './type-category.reducer';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.typeCategories
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectTranslatedItems = createSelector(
  selectItems,
  CommonUserProfileSelectors.selectLanguage,
  (items: SelectListItemWithTranslationDto[], lang: string) =>
    convertSelectListItemDtoToSelectListItem(items, lang)
);

export const TypeCategorySelectors = {
  selectItems,
  selectLoaded,
  selectTranslatedItems
};
