import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as CountriesReducer from './countries/countries.reducer';
import * as GendersReducer from './genders/genders.reducer';
import * as ProcurementCodesReducer from './procurement-codes/procurement-codes.reducer';
import * as UsersReducer from './users/users.reducer';
import * as TypeCategoryReducer from './type-category/type-category.reducer';
import * as OperationReducer from './operations/operations.reducer';

export const FEATURE_NAME = 'reference-data';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  countries: CountriesReducer.reducer,
  genders: GendersReducer.reducer,
  procurementCodes: ProcurementCodesReducer.reducer,
  users: UsersReducer.reducer,
  typeCategories: TypeCategoryReducer.reducer,
  operations: OperationReducer.reducer
};

export interface State {
  countries: CountriesReducer.State;
  genders: GendersReducer.State;
  procurementCodes: ProcurementCodesReducer.State;
  users: UsersReducer.State;
  typeCategories: TypeCategoryReducer.State;
  operations: OperationReducer.State
}
