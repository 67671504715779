import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StockFlowReferenceDataService } from '@stock-flow/data-access';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { CountriesActions } from './countries.actions';
import { SelectListItemWithTranslationDto } from '@common/angular/core/models';

@Injectable()
export class CountriesEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesActions.get),
      exhaustMap(() =>
        this.service.getCountries().pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) return CountriesActions.error;
            return CountriesActions.getSuccess({ items });
          }),
          catchError(() => of(CountriesActions.error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: StockFlowReferenceDataService
  ) {}
}
