export enum ProcurementListSortBy {
  NameAscending = 1,
  NameDescending = 2,

  DescriptionAscending = 3,
  DescriptionDescending = 4,

  StatusAscending = 5,
  StatusDescending = 6,

  CreatedByAscending = 7,
  CreatedByDescending = 8,

  CreatedDateAscending = 9,
  CreatedDateDescending = 10,

  UpdatedDateAscending = 11,
  UpdatedDateDescending = 12
}