import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ProcurementService,
  StockFlowReferenceDataService,
  StockFlowUserProfileService
} from './services';

@NgModule({
  imports: [CommonModule],
  providers: [
    ProcurementService,
    StockFlowUserProfileService,
    StockFlowReferenceDataService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class StockFlowDataAccessModule {}
