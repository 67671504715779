import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reports.reducer';
import * as reducer from './report-generation.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.reports
);

const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectLoading = createSelector(selectState, reducer.getLoading);

export const ReportGenerationSelectors = {
  selectLoaded,
  selectLoading
};
