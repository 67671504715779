import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { SersiAngularUiCoreModule } from '@sersi/angular/ui/core';
import { SersiAngularFormlyCoreModule } from '@sersi/angular/formly/core';
import { SersiAngularUiTypographyModule } from '@sersi/angular/ui/typography';
import { MultiEmailInputTypeComponent } from './multi-email-input-type';
import { ChipsModule } from 'primeng/chips';

@NgModule({
  imports: [
    CommonModule,
    SersiAngularFormlyCoreModule,
    FormlySelectModule,
    ReactiveFormsModule,
    FormlyModule,
    SersiAngularUiCoreModule,
    SersiAngularUiTypographyModule,
    FormsModule,
    TooltipModule,
    ChipsModule
  ],
  declarations: [MultiEmailInputTypeComponent],
  exports: [MultiEmailInputTypeComponent]
})
export class SersiAngularFormlyTypesMultiEmailSelectModule {}
