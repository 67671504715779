import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { UsersActions } from './users.actions';
import * as reducer from './users.reducer';
import { UsersSelectors } from './users.selectors';

@Injectable()
export class UsersFacade {

  loaded$ = this.store.select(UsersSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(UsersSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) { }

  get(): void {
    this.store.dispatch(UsersActions.get());
  }
}
