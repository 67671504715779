import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReportPDFData } from '../interfaces/report-pdf-data.type';

@Injectable({ providedIn: 'root' })
export class DomainReportsStateService {

  pdfData: ReportPDFData;

  get isLoaded$(): Observable<boolean> {
    return this._isLoaded$.asObservable();
  }

  private _isLoaded$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.pdfData = { source: null };
  }

  setReportPDF(source: Uint8Array | null): void {
    this.pdfData.source = source;
    this._isLoaded$.next(source !== null);
  }

  resetLoadedReport(): void {
    this.pdfData.source = null;
    this._isLoaded$.next(false);
  }

  handleError(): void {
    this._isLoaded$.next(true);
  }

}
