import { createAction, props } from '@ngrx/store';
import { SelectListItemWithTranslationDto } from '@common/angular/core/models';

const NAMESPACE = '[Reference - Procurement Codes] - ';

const get = createAction(`${NAMESPACE} Get`, props<{ operationId: string }>());

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemWithTranslationDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const ProcurementCodesActions = {
  error,
  get,
  getSuccess
};
