import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation
} from '@angular/core';
import { DomainReportsStateService } from '../../services';
import { ReportGenerationFacade } from '../../+state/report-generation/report-generation.facade';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { scopeLoader } from '@common/angular/translation';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { ReportTypeEnum } from '../../enums';

@Component({
  selector: 'sf-reports-preview-dialog',
  templateUrl: './reports-preview-dialog.component.html',
  styleUrls: ['./reports-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'domain-reports',
        alias: 'domain-reports',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class ReportsPreviewDialogComponent {
  readonly translateScope = 'domain-reports.components.reports-preview-dialog';

  private domainReportsStateService = inject(DomainReportsStateService);

  isLoaded$ = this.domainReportsStateService.isLoaded$;
  pdfData = this.domainReportsStateService.pdfData;
  procurementId: string;
  type: ReportTypeEnum;

  constructor(
    private reportGenerationFacade: ReportGenerationFacade,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    this.procurementId = this.config.data.procurementId;
    this.type = this.config.data.type;
  }

  onDownload(): void {
    switch (this.type) {
      case ReportTypeEnum.BUY_ORDER:
        this.reportGenerationFacade.generateBuyOrderReport(
          this.procurementId,
          false
        );
        break;
      case ReportTypeEnum.PROCUREMENT_SUMMARY:
        this.reportGenerationFacade.generateSummaryReport(
          this.procurementId,
          false
        );
        break;
      default:
        break;
    }

    this.dialogRef.close();
  }

  protected readonly ReportTypeEnum = ReportTypeEnum;
}
