<ng-container *transloco="let t; read: translateScope">
  <ng-container *ngLet="isLoaded$ | async as isLoaded">
    <sersi-spin [spin]="!isLoaded" class="loading-container">
      <ng-container *ngIf="isLoaded && pdfData.source">

        <button
          pButton
          pRipple
          type="button"
          class="p-button-outlined mr-3 mb-3"
          data-ele="report-preview-btn"
          iconPos="left"
          [rounded]="true"
          [icon]="'pi pi-download'"
          [label]="this.type === ReportTypeEnum.PROCUREMENT_SUMMARY ? t('download-summary') : t('download-buy-order')"
          (click)="onDownload()"
        ></button>

        <pdf-viewer
          [src]="pdfData.source"
          [render-text]="true"
          style="height: 80vh"
        ></pdf-viewer>
      </ng-container>
    </sersi-spin>
  </ng-container>
</ng-container>

