import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { ReportGenerationActions } from './report-generation.actions';

import { downloadFile } from '@common/angular/utils';
import { MessageService } from 'primeng/api';
import {
  DomainReportsGenerationService,
  DomainReportsMessageService,
  DomainReportsStateService
} from '../../services';

@Injectable()
export class ReportGenerationEffects {
  readonly translateScope = 'domain-reports.+state.effects';

  constructor(
    private actions$: Actions,
    private domainReportGenerationService: DomainReportsGenerationService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private domainReportsStateService: DomainReportsStateService,
    private domainReportsMessageService: DomainReportsMessageService
  ) {}

  generateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportGenerationActions.generateSummary),
      switchMap((action) =>
        this.domainReportGenerationService
          .getProcurementSummary(action.procurementId)
          .pipe(
            tap((resp) => this.domainReportsStateService.setReportPDF(resp)),
            filter(() => !action.isPreview),
            map((result: any) => {
              downloadFile(
                result,
                'Procurement Summary.pdf',
                'application.pdf'
              );
              return ReportGenerationActions.generateSummarySuccess();
            }),
            catchError(() => {
              this.domainReportsStateService.handleError();
              return of(ReportGenerationActions.error());
            }),
            takeUntil(this.actions$.pipe(ofType(ReportGenerationActions.reset)))
          )
      )
    )
  );

  generateBuyOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportGenerationActions.generateBuyOrder),
      switchMap((action) =>
        this.domainReportGenerationService
          .getBuyOrderSummary(action.procurementId)
          .pipe(
            tap((resp) => this.domainReportsStateService.setReportPDF(resp)),
            filter(() => !action.isPreview),
            map((result: any) => {
              downloadFile(result, 'Buy Order.pdf', 'application.pdf');
              return ReportGenerationActions.generateBuyOrderSuccess();
            }),
            catchError(() => {
              this.domainReportsStateService.handleError();
              return of(ReportGenerationActions.error());
            }),
            takeUntil(this.actions$.pipe(ofType(ReportGenerationActions.reset)))
          )
      )
    )
  );

  downloadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportGenerationActions.error),
        tap(() => this.domainReportsMessageService.showDownloadError())
      ),
    { dispatch: false }
  );
}
