import { Injectable } from '@angular/core';

import { CommonAppConfig } from '@common/angular/config';

import { getAppConfig } from '../utils';

@Injectable()
export class AppConfigService {

  getConfig(): CommonAppConfig {
    return getAppConfig();
  }

}
