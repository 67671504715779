import { CommonAppConfig } from '@common/angular/config';

export const APP_CONFIG_STAGING: CommonAppConfig = {
  apiUrl: 'https://stockflow-api.staging.fms.feedlothealth.com/api',
  authServerUrl: 'https://identity.staging.fms.feedlothealth.com',
  production: true,
  showDevFeature: false,
  environmentName: 'staging',
  integrations: {
    heapAppId: '1446086678'
  }
};
