import { createAction, props } from '@ngrx/store';
import { SelectListItemWithTranslationDto } from '@common/angular/core/models';

const NAMESPACE = '[Reference - Operations] - ';

const get = createAction(`${NAMESPACE} Get`);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemWithTranslationDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const OperationsActions = {
  error,
  get,
  getSuccess
};
