import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-calendar-range-type',
  templateUrl: './calendar-range-type.component.html',
  styleUrls: ['./calendar-range-type.component.scss']
})
export class CalendarRangeTypeComponent extends FieldType<FieldTypeConfig> {
  type: 'datepicker' | Type<CalendarRangeTypeComponent>;
  override defaultOptions = {
    props: {
      label: 'Datepicker',
      placeholder: '',
      description: 'Description',
      dateFormat: 'M dd, yy',
      hourFormat: '24',
      numberOfMonths: 1,
      selectionMode: 'range',
      required: true,
      readonlyInput: false,
      showTime: false,
      showButtonBar: true,
      showIcon: true,
      showOtherMonths: true,
      selectOtherMonths: false,
      monthNavigator: false,
      yearNavigator: false,
      yearRange: '2020:2030',
      inline: false
    }
  };
}
