import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { STOCK_FLOW_API_CONFIG } from '@stock-flow/data-access';

@Injectable()
export class DomainReportsGenerationService {
  constructor(
    private http: HttpClient,
    @Inject(STOCK_FLOW_API_CONFIG) private apiUrl: string
  ) {}

  getProcurementSummary(procurementId: string): Observable<any> {
    const options: object = {
      responseType: 'arraybuffer'
    };

    return this.http.get<any>(
      `${this.apiUrl}/Procurements/${procurementId}/Summary`,
      options
    );
  }

  getBuyOrderSummary(procurementId: string): Observable<any> {
    const options: object = {
      responseType: 'arraybuffer'
    };

    return this.http.get<any>(
      `${this.apiUrl}/Procurements/${procurementId}/BuyOrder`,
      options
    );
  }
}
