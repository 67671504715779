import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as ReportGenerationReducer from './report-generation/report-generation.reducer';

export const FEATURE_NAME = 'domain-reports';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  reports: ReportGenerationReducer.reducer
};

export interface State {
  reports: ReportGenerationReducer.State;
}
