import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { initSentry } from '@common/angular/integrations/sentry';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getAppConfig } from './utils';

if (environment.production) {
  enableProdMode();
}

const { integrations, environmentName } = getAppConfig();

initSentry(integrations?.sentryDsn, { environmentName });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
