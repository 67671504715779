import { Component,ChangeDetectionStrategy } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-color-box-type',
  templateUrl: './color-box-type.component.html',
  styleUrls: ['./color-box-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorBoxTypeComponent extends FieldType<FieldTypeConfig> {
}
