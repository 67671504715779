import { CommonAppConfig } from '@common/angular/config';

export const APP_CONFIG_PRODUCTION: CommonAppConfig = {
  apiUrl: 'https://stockflow-api.frm.feedlothealth.com/api',
  authServerUrl: 'https://identity.frm.feedlothealth.com',
  production: true,
  showDevFeature: false,
  environmentName: 'production',
  integrations: {
    heapAppId: '3677809513',
    sentryDsn: 'https://1f949505d53838d15e043dbbcc3ff5d7@o4506538482532352.ingest.us.sentry.io/4508016057778176'
  }
};
