import { SelectListItemWithTranslationDto } from '@common/angular/core/models';
import { CommonUserProfileSelectors } from '@common/angular/features/user-profile';
import { convertSelectListItemDtoToSelectListItem } from '@common/angular/translation';
import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './countries.reducer';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.countries
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectTranslatedItems = createSelector(
  selectItems,
  CommonUserProfileSelectors.selectLanguage,
  (items: SelectListItemWithTranslationDto[], lang: string) =>
    convertSelectListItemDtoToSelectListItem(items, lang)
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectCountryById = (id: string) =>
  createSelector(selectTranslatedItems, (items: SersiSelectListItem[]) => {
    const findItem = items?.find((item: SersiSelectListItem) => item.id === id);

    return findItem ? findItem.code : '';
  });

export const CountriesSelectors = {
  selectItems,
  selectLoaded,
  selectTranslatedItems,
  selectCountryById
};
