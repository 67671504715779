import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-editor-type',
  templateUrl: './editor-type.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EditorTypeComponent extends FieldType<FieldTypeConfig>{
  override defaultOptions = {
    props: {
      modules:{
        toolbar : true
      },
      toolbarLabels:  {
        formats: {
          headingTitle: 'Heading',
          subHeadingTitle: 'Subheading',
          normalTitle: 'Normal'
        }
      },
      placeholder: 'Insert text here ...'
    }
  };
}
