import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ReportGenerationActions } from './report-generation.actions';
import * as reportGenerationReducer from './report-generation.reducer';
import { ReportGenerationSelectors } from './report-generation.selectors';

@Injectable()
export class ReportGenerationFacade {
  constructor(private store: Store<reportGenerationReducer.State>) {}

  isLoading$: Observable<boolean> = this.store.select(
    ReportGenerationSelectors.selectLoading
  );

  isLoaded$: Observable<boolean> = this.store.select(
    ReportGenerationSelectors.selectLoaded
  );

  generateSummaryReport(procurementId: string, isPreview: boolean): void {
    this.store.dispatch(
      ReportGenerationActions.generateSummary({ procurementId, isPreview })
    );
  }

  generateBuyOrderReport(procurementId: string, isPreview: boolean): void {
    this.store.dispatch(
      ReportGenerationActions.generateBuyOrder({ procurementId, isPreview })
    );
  }

  reset(): void {
    this.store.dispatch(ReportGenerationActions.reset());
  }
}
