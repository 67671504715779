import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';

import { SersiAngularFormlyCoreModule } from '@sersi/angular/formly/core';

import { DefaultFormFieldWrapperComponent } from './default-form-field-wrapper/default-form-field-wrapper.component';
import { DefaultHorizontalFormFieldWrapperComponent } from './default-horizontal-form-field-wrapper/default-horizontal-form-field-wrapper.component';
import { FormFieldWithToggleWrapperComponent } from './form-field-with-toggle-wrapper/form-field-with-toggle-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    TooltipModule,
    CheckboxModule,
    SersiAngularFormlyCoreModule
  ],
  declarations: [
    DefaultFormFieldWrapperComponent,
    DefaultHorizontalFormFieldWrapperComponent,
    FormFieldWithToggleWrapperComponent
  ],
  exports :[
    DefaultFormFieldWrapperComponent,
    DefaultHorizontalFormFieldWrapperComponent,
    FormFieldWithToggleWrapperComponent
  ]
})
export class SersiAngularFormlyWrappersDefaultModule {}
