import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PreventUnsavedChangesGuard } from './guards';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true
        }
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      name: 'SF - Platform',
      logOnly: false
      , connectInZone: true }),
    EffectsModule.forRoot([])
  ],
  providers: [
    PreventUnsavedChangesGuard
  ]
})
export class StockFlowDomainStateRootModule {}
