import { CommonAppConfig } from '@common/angular/config';

import {
  APP_CONFIG_DEV, APP_CONFIG_LOCAL, APP_CONFIG_PRODUCTION,
  APP_CONFIG_QA, APP_CONFIG_STAGING, APP_CONFIG_UAT
} from '../configs';

export function getAppConfig(): CommonAppConfig {
  const hostname = window.location.hostname;

  switch (hostname) {
    case 'stockflow.dev.fms.feedlothealth.com':
      return APP_CONFIG_DEV;
    case 'stockflow.qa.fms.feedlothealth.com':
      return APP_CONFIG_QA;
    case 'stockflow.staging.fms.feedlothealth.com':
      return APP_CONFIG_STAGING;
    case 'stockflow.uat.fms.feedlothealth.com':
      return APP_CONFIG_UAT;
    case 'stockflow.frm.feedlothealth.com':
      return APP_CONFIG_PRODUCTION;
    default:
      return APP_CONFIG_LOCAL;
  }

}
