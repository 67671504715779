import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserProfileDto } from '@common/angular/core/models';
import { Observable } from 'rxjs';
import { STOCK_FLOW_API_CONFIG } from '../../tokens';

@Injectable()
export class StockFlowUserProfileService {
  constructor(
    private http: HttpClient,
    @Inject(STOCK_FLOW_API_CONFIG) private apiUrl: string
  ) {
  }

  getAuthUserProfile(authUserId: string): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(`${this.apiUrl}/UserProfile/${authUserId}/AuthProfile`);
  }

  updateUserProfile(dto: UserProfileDto): Observable<UserProfileDto> {
    return this.http.put<UserProfileDto>(`${this.apiUrl}/UserProfile/${dto.id}/Profile`, dto);
  }

}
