import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StockFlowReferenceDataService } from '@stock-flow/data-access';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ProcurementCodesActions } from './procurement-codes.actions';
import { SelectListItemWithTranslationDto } from '@common/angular/core/models';

@Injectable()
export class ProcurementCodesEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcurementCodesActions.get),
      exhaustMap((action) =>
        this.service.getProcurementCodes(action.operationId).pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) return ProcurementCodesActions.error;
            return ProcurementCodesActions.getSuccess({ items });
          }),
          catchError(() => of(ProcurementCodesActions.error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: StockFlowReferenceDataService
  ) {}
}
