import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CountriesEffects } from './+state/countries/countries.effects';
import { CountriesFacade } from './+state/countries/countries.facade';
import { ReferenceDataEffects } from './+state/effects';
import { StockFlowReferenceDataFacade } from './+state/facade';
import { GendersEffects } from './+state/genders/genders.effects';
import { GendersFacade } from './+state/genders/genders.facade';
import { ProcurementCodesEffects } from './+state/procurement-codes/procurement-codes.effects';
import { ProcurementCodesFacade } from './+state/procurement-codes/procurement-codes.facade';
import { FEATURE_NAME, reducer } from './+state/reducer';
import { UsersEffects } from './+state/users/users.effects';
import { UsersFacade } from './+state/users/users.facade';
import { TypeCategoryEffects } from './+state/type-category/type-category.effects';
import { TypeCategoryFacade } from './+state/type-category/type-category.facade';
import { OperationsEffects } from './+state/operations/operations.effects';
import { OperationsFacade } from './+state/operations/operations.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      CountriesEffects,
      GendersEffects,
      ProcurementCodesEffects,
      UsersEffects,
      ReferenceDataEffects,
      TypeCategoryEffects,
      OperationsEffects
    ])
  ],
  providers: [
    CountriesFacade,
    GendersFacade,
    ProcurementCodesFacade,
    UsersFacade,
    StockFlowReferenceDataFacade,
    TypeCategoryFacade,
    OperationsFacade
  ]
})
export class StockFlowDomainStateReferenceDataModule {}
