import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthSecuredRouteGuard } from '@common/angular/auth';

const ROUTES: Route[] = [
  {
    path: 'health',
    loadChildren: () => import('@common/angular/health').then(m => m.CommonAngularHealthModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@common/angular/features/auth').then(m => m.CommonAngularFeatureAuthModule)
  },
  {
    path: '',
    loadChildren: () => import('@stock-flow/main').then(m => m.StockFlowMainModule),
    canMatch: [ AuthSecuredRouteGuard ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
