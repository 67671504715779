import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { ProcurementCodesActions } from './procurement-codes.actions';
import * as reducer from './procurement-codes.reducer';
import { ProcurementCodesSelectors } from './procurement-codes.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class ProcurementCodesFacade {
  loaded$ = this.store.select(ProcurementCodesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(
    this.store.select(ProcurementCodesSelectors.selectTranslatedItems),
    this.loaded$
  );

  constructor(private store: Store<reducer.State>) {}

  get(operationId: string): void {
    this.store.dispatch(ProcurementCodesActions.get({ operationId }));
  }

  getProcurementCodeDescriptionById(id: string): Observable<string> {
    return this.store.select(
      ProcurementCodesSelectors.selectProcurementCodeDescriptionById(id)
    );
  }
}
