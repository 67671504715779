import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { OperationsActions } from './operations.actions';
import * as reducer from './operations.reducer';
import { OperationsSelectors } from './operations.selectors';

@Injectable()
export class OperationsFacade {
  loaded$ = this.store.select(OperationsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(
    this.store.select(OperationsSelectors.selectTranslatedItems),
    this.loaded$
  );

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(OperationsActions.get());
  }
}
