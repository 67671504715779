import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

import { HashMap, TranslocoService } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class DomainReportsMessageService {
  private readonly translateScope = 'domain-reports.services.error-messages';

  private readonly messageService = inject(MessageService);
  private readonly translationService = inject(TranslocoService);

  showDownloadError(): void {
    this.showErrorToast(
      this.getTranslation('download-err.title'),
      this.getTranslation('download-err.message')
    );
  }

  private showErrorToast(summary: string, detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary,
      detail
    });
  }

  private getTranslation(key: string, params?: HashMap): string {
    return this.translationService.translate(
      `${this.translateScope}.${key}`,
      params
    );
  }
}
