import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';

import {
  AUTH_CONFIG_API_URLS,
  AUTH_CONFIG_SERVER_URL,
  CommonAngularAuthModule
} from '@common/angular/auth';
import {
  APP_CONFIG,
  getApiUrl,
  getAuthServerUrls,
  getConfig,
  getWindow,
  SHOW_DEV_FEATURE,
  WINDOW
} from '@common/angular/config';
import { PARAM_ESTIMATES_URL } from '@common/angular/core/models';
import { AH_USER_API_URL } from '@common/angular/core/models';
import { CommonAngularErrorHandlingModule } from '@common/angular/error-handling';
import { HeapModule } from '@common/angular/integrations/heap';
import { CommonAngularTranslationLoaderModule } from '@common/angular/translation';
import { CommonAngularUrlRouterModule } from '@common/angular/url-router';
import { CommonAngularUtilsModule } from '@common/angular/utils';
import { NgSelectModule } from '@ng-select/ng-select';
import { StockFlowCoreFormlyModule } from '@stock-flow/core/formly';
import {
  STOCK_FLOW_API_CONFIG,
  StockFlowDataAccessModule
} from '@stock-flow/data-access';
import { StockFlowDomainStateReferenceDataModule } from '@stock-flow/domain/state/reference-data';
import { StockFlowDomainStateRootModule } from '@stock-flow/domain/state/root';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppConfigService } from '../services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StockFlowDomainReportsModule } from '@stock-flow/domain/reports';

const getShowDevFeature = (appConfig: AppConfigService): boolean => {
  return !!appConfig.getConfig().showDevFeature;
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastModule,
    NgSelectModule,
    CommonAngularTranslationLoaderModule,
    StockFlowDomainStateRootModule,
    StockFlowCoreFormlyModule,
    StockFlowDomainStateReferenceDataModule,
    StockFlowDomainReportsModule,
    StockFlowDataAccessModule,
    CommonAngularUrlRouterModule,
    CommonAngularAuthModule.forRoot({
      clientId: 'stock-flow',
      scope: 'openid profile AnimalHealth.Service.StockFlow.All offline_access',
      forceUserLogin: true
    }),
    CommonAngularUtilsModule,
    CommonAngularErrorHandlingModule,
    HeapModule
  ],
  providers: [
    AppConfigService,
    MessageService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_CONFIG,
      useFactory: getConfig,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AUTH_CONFIG_SERVER_URL,
      useFactory: getAuthServerUrls,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: SHOW_DEV_FEATURE,
      useFactory: getShowDevFeature,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: STOCK_FLOW_API_CONFIG,
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_USER_API_URL,
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: WINDOW,
      useFactory: getWindow,
      multi: false
    },
    {
      provide: AUTH_CONFIG_SERVER_URL,
      useFactory: getAuthServerUrls,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AUTH_CONFIG_API_URLS,
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: PARAM_ESTIMATES_URL,
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
