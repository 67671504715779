import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { ChipsAddEvent, ChipsRemoveEvent } from 'primeng/chips/chips.interface';

@Component({
  selector: 'sersi-multi-email-input',
  templateUrl: './multi-email-input-type.component.html',
  styleUrls: ['./multi-email-input-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiEmailInputTypeComponent extends FieldType<FieldTypeConfig> {
  get control(): FormControl {
    return this.formControl as FormControl;
  }

  onAdd(event: ChipsAddEvent): void {
    const email = event.value;
    if (!this.validateEmail(email)) {
      this.formControl.setErrors({ invalidEmail: true });
    } else {
      if (this.formControl.hasError('invalidEmail')) {
        this.formControl.setErrors(null);
      }
    }
  }

  onRemove(event: ChipsRemoveEvent): void {
    if (
      this.formControl.value.every((email: string) => this.validateEmail(email))
    ) {
      this.formControl.setErrors(null);
    }
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  get errorMessage(): string {
    if (this.formControl.hasError('invalidEmail')) {
      return 'One or more email addresses are invalid';
    }
    return '';
  }
}
