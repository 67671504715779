import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { TypeCategoryActions } from './type-category.actions';
import * as reducer from './type-category.reducer';
import { TypeCategorySelectors } from './type-category.selectors';

@Injectable()
export class TypeCategoryFacade {
  loaded$ = this.store.select(TypeCategorySelectors.selectLoaded);
  items$ = getLoadedSelectItems(
    this.store.select(TypeCategorySelectors.selectTranslatedItems),
    this.loaded$
  );

  constructor(private store: Store<reducer.State>) {}

  get(operationId: string): void {
    this.store.dispatch(TypeCategoryActions.get({ operationId }));
  }
}
