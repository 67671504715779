<span class='p-input-icon-left' [ngClass]="{'p-input-icon-right': field.formControl.value}">
    <i class='pi pi-search' [attr.data-ele]="'search-icon'"></i>
    <input
      [type]='to.type'
      pInputText
      [placeholder]="(to['placeholder$'] | async) || ''"
      [formControl]='formControl'
      [formlyAttributes]='field'
      [attr.data-ele]='key'
      [style.width]="to['width']"
    />
    <i class='pi pi-times' *ngIf="field.formControl.value" style="cursor: pointer;" (click)="cancel()" [attr.data-ele]="'cancel-icon'"></i>
</span>
